import React, { useState, useEffect, useCallback, useContext, } from "react";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    makeStyles,
    useTheme,
    Theme,
    CircularProgress,
    Box
} from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { Trans } from "react-i18next";

import { fetchPost } from "../../utils/fetch";
import { Mr1 } from "../../views/ProductMr1";
import { FilterChartStatusLog, FilterChartStatusLogData } from "../filter/FilterChartStatusLog";
import { AppContext } from "../../store/context";
import { ChartData, ChartOptions } from "chart.js";
import moment, { Moment } from "moment-timezone";

interface StatusData {
    statusData1: number[];
    statusName1: string;
    statusDescription1: string;
    statusData2: number[];
    statusName2: string;
    statusDescription2: string;
    timeValuesType1: string[];
    timeValuesType2: string[];
}

interface ChartStatusLogProps {
    radar: Mr1;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
    item: {
        marginBottom: theme.spacing(2)
    },
    circularProgress: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
}));

export const ChartStatusLog = (props: ChartStatusLogProps): React.ReactElement => {
    const { state } = useContext(AppContext);
    const classes = useStyles();
    const theme = useTheme();
    const [statusData, setstatusData] = useState<undefined | StatusData>(undefined);

    const [isLoading, setisLoading] = useState<boolean>(true);
    const [filterData, setfilterDate] = useState<FilterChartStatusLogData>(state.ui.filter.status);

    useEffect(() => {
        const requestStatusData = (currentFilterData: FilterChartStatusLogData): void => {
            if (state.user) {
                setstatusData(undefined);
                setisLoading(true);
                fetchPost("get_status_log_data.php", {
                    params: {
                        token: state.user.token,
                        databaseName: props.radar.name,
                        siteId: props.radar.siteId,
                        dateFrom: currentFilterData.dateFrom.format("YYYY-MM-DD") + " 00:00:00",
                        dateTo: currentFilterData.dateTo.format("YYYY-MM-DD") + " 23:59:59",
                        statusType1: currentFilterData.statusTypeIdPlot1.toString(),
                        statusType2: currentFilterData.statusTypeIdPlot2.toString()
                    }
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data) {
                            setstatusData(data);
                        }
                        setisLoading(false);
                    });
            }
        };
        requestStatusData(filterData);
    }, [filterData, props.radar, state.user]);

    const changeFilterData = useCallback(
        (newFilterData: FilterChartStatusLogData): void => {
            setfilterDate(newFilterData);
            setisLoading(true);
        },
        [setfilterDate, setisLoading]
    );

    if (statusData === undefined) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                    <FilterChartStatusLog onChangeFilterData={changeFilterData} databaseName={props.radar.name} />
                </Grid>
                <Grid item xs={12} md={10}>
                    <Card className={classes.item}>
                        <CardContent style={{ position: "relative" }}>
                            <Typography variant="overline">
                                <Trans>system.statusTitle</Trans>
                            </Typography>

                            {isLoading && (
                                <Box className={classes.circularProgress}>
                                    <CircularProgress></CircularProgress>
                                </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>)
    }

    const data: ChartData<'line', { x: Moment, y: number }[]> = {
        datasets: [
            {
                xAxisID: "x",
                yAxisID: "y1",
                label: statusData.statusName1,
                fill: false,
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.light,
                data: statusData.statusData1.map((value, index) => ({
                    x: moment(statusData.timeValuesType1[index], "YYYY-MM-DD HH:mm:ss"),
                    y: value
                }))
            }
        ]
    };

    if (statusData.statusData2 && 0 < statusData.statusData2.length) {
        data.datasets.push(
            {
                xAxisID: "x",
                yAxisID: "y2",
                label: statusData.statusName2,
                fill: false,
                borderColor: theme.palette.primary.dark,
                backgroundColor: theme.palette.primary.dark,
                data: statusData.statusData2.map((value, index) => ({
                    x: moment(statusData.timeValuesType2[index], "YYYY-MM-DD HH:mm:ss"),
                    y: value
                }))
            }
        )
    }

    const options: ChartOptions<'line'> = {
        elements: { point: { radius: 0 } },
        scales: {
            x:
            {
                type: "time",
                time: {
                    unit: "hour",
                    tooltipFormat: "DD.MM.YYYY HH:mm",
                    displayFormats: {
                        hour: "HH:mm"
                    }
                },
                ticks: {
                    maxRotation: 45,
                    padding: 0,
                    labelOffset: 0,
                    maxTicksLimit: 12
                }
            },
            y2:
            {
                position: 'right',
            }
        },
        animation: {
            duration: 0
        },
        plugins: {
            legend: {
                display: true,
            },
            datalabels: {
                display: false
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
                <FilterChartStatusLog onChangeFilterData={changeFilterData} databaseName={props.radar.name} />
            </Grid>
            <Grid item xs={12} md={10}>
                <Card className={classes.item}>
                    <CardContent style={{ position: "relative" }}>
                        <Typography variant="overline">
                            <Trans>system.statusTitle</Trans>
                        </Typography>

                        <Line data={data} options={options} plugins={[]} />
                        {isLoading && (
                            <Box className={classes.circularProgress}>
                                <CircularProgress></CircularProgress>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};
