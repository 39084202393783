import React, { useCallback, useContext, useState, useEffect } from "react";
import { Card, CardContent, Grid, Typography, Box, Button, makeStyles, FormControl, InputLabel } from "@material-ui/core";
import NativeSelect from "@material-ui/core/Select";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AppContext } from "../../store/context";
import { fetchPost } from "../../utils/fetch";

import { Trans } from "react-i18next";
import moment, { Moment } from "moment";
import { UITypes } from "../../store/reducer";

interface StatusTypeData {
    statusTypeNames: string[];
    statusTypeIds: number[];
    statusTypeDescriptions: string[];
}

const useStyles = makeStyles({
    fullWidth: {
        width: "100%"
    }
});

export interface FilterChartStatusLogData {
    dateFrom: Moment;
    dateTo: Moment;
    statusTypeIdPlot1: number;
    statusTypeIdPlot2: number;
}

interface FilterChartStatusLogProps {
    onChangeFilterData: (newFilterData: FilterChartStatusLogData) => void;
    databaseName: string;
}

export const FilterChartStatusLog = (props: FilterChartStatusLogProps): React.ReactElement => {
    const { state } = useContext(AppContext);
    const { dispatch } = useContext(AppContext);
    const classes = useStyles();
    const selectedFilterData = state.ui.filter.status;

    const [statusTypeData, setstatusTypsData] = useState<StatusTypeData | undefined>(undefined);

    useEffect((): void => {
        if (props.databaseName && state.user) {
            fetchPost("get_status_types.php", {
                params: {
                    token: state.user.token,
                    databaseName: props.databaseName
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    setstatusTypsData(data);
                });
        }
    }, [props.databaseName, state.user]);

    const setSelectedFilterData = useCallback(
        (data: FilterChartStatusLogData) => {
            dispatch({
                type: UITypes.SetStatusFilter,
                payload: data
            });
        },
        [dispatch]
    );
    const handleDateChangeFrom = (date: Moment | null): void => {
        setSelectedFilterData({ ...selectedFilterData, dateFrom: moment(date).utc() });
    };
    const handleDateChangeTo = (date: Moment | null): void => {
        setSelectedFilterData({ ...selectedFilterData, dateTo: moment(date).utc() });
    };

    const handleStatusTypeChangePlot1 = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        const statusTypeId = event.currentTarget.value as number;

        if (statusTypeData) {
            setSelectedFilterData({ ...selectedFilterData, statusTypeIdPlot1: statusTypeId });
        }
    };
    const handleStatusTypeChangePlot2 = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ): void => {
        const statusTypeId = event.currentTarget.value as number;

        if (statusTypeData) {
            setSelectedFilterData({ ...selectedFilterData, statusTypeIdPlot2: statusTypeId });
        }
    };


    const { onChangeFilterData } = props;
    const updateFilterData = useCallback((): void => {
        onChangeFilterData(selectedFilterData);
    }, [selectedFilterData, onChangeFilterData]);

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant="overline">
                            <Trans>filter.title</Trans>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <DatePicker
                                format="DD.MM.YYYY"
                                label={<Trans>filter.dateStart</Trans>}
                                value={selectedFilterData.dateFrom}
                                onChange={handleDateChangeFrom}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <DatePicker
                                format="DD.MM.YYYY"
                                label={<Trans>filter.dateEnd</Trans>}
                                value={selectedFilterData.dateTo}
                                onChange={handleDateChangeTo}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        {statusTypeData && (
                            <Box marginTop={1}>
                                <FormControl className={classes.fullWidth}>
                                    <InputLabel htmlFor="classSelect">
                                        <Trans>filter.class</Trans>
                                    </InputLabel>
                                    <NativeSelect
                                        native
                                        defaultValue={selectedFilterData.statusTypeIdPlot1}
                                        id="classSelect"
                                        onChange={handleStatusTypeChangePlot1}
                                        label={<Trans>filter.class</Trans>}
                                    >
                                        <option label={""} key={"empty"} value={-1} />
                                        {statusTypeData.statusTypeNames.map((statusTypeName: string, index) => {
                                            return (
                                                <option
                                                    label={statusTypeName}
                                                    key={statusTypeName}
                                                    value={statusTypeData.statusTypeIds[index]}
                                                />
                                            );
                                        })}
                                    </NativeSelect>
                                </FormControl>
                            </Box>
                        )}
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        {statusTypeData && (
                            <Box marginTop={1}>
                                <FormControl className={classes.fullWidth}>
                                    <InputLabel htmlFor="classSelect">
                                        <Trans>filter.class</Trans>
                                    </InputLabel>
                                    <NativeSelect
                                        native
                                        defaultValue={selectedFilterData.statusTypeIdPlot2}
                                        id="classSelect"
                                        onChange={handleStatusTypeChangePlot2}
                                        label={<Trans>filter.class</Trans>}
                                    >
                                        <option label={""} key={"empty"} value={-1} />
                                        {statusTypeData.statusTypeNames.map((statusTypeName: string, index) => {
                                            return (
                                                <option
                                                    label={statusTypeName}
                                                    key={statusTypeName}
                                                    value={statusTypeData.statusTypeIds[index]}
                                                />
                                            );
                                        })}
                                    </NativeSelect>
                                </FormControl>
                            </Box>
                        )}
                    </Grid>
                    <Grid container alignItems="flex-start" justifyContent="flex-end" direction="row">
                        <Box marginTop={2}>
                            <Button variant="contained" color="primary" type="submit" onClick={updateFilterData}>
                                <Trans>filter.reload</Trans>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
