import React, { useState, useCallback } from "react";
import { Card, CardContent, CircularProgress, makeStyles } from "@material-ui/core";
import { PDFViewer } from "@react-pdf/renderer";
import { Page, Text, Image, View, Document } from "@react-pdf/renderer";
import moment from "moment";
import { styles } from "./ReportStyles";
import { Mr1 } from "../../../views/ProductMr1";

import { FilterReportData } from "./FilterReport";
import { OverallRadarData, ReportDataOverview, Windmill, WindmillShutdownParameter } from "./ReportDataOverview";
import { ReportDataChartMtrPerDayWithPerformance } from "./ReportDataChartMtrPerDayWithPerformance";
import { ReportDataPieOperationTime } from "./ReportDataPieOperationTime";
import { useTranslation } from "react-i18next";
import { ReportDataPie } from "./ReportDataPie";

const useStyles = makeStyles({
    circularProgressCard: {
        width: "100%",
        height: "100%"
    },
    circularProgress: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
});

interface ReportMonthlyProps {
    radar: Mr1;
    filterData: FilterReportData;
}

export const ReportMonthly = (props: ReportMonthlyProps): React.ReactElement => {
    const [overallRadarData, setoverallRadarData] = useState<undefined | OverallRadarData>(undefined);
    const [imageChartMtrPerDayWithPerformance, setimageChartMtrPerDayWithPerformance] = useState<undefined | string>(
        undefined
    );
    const [imagePieShutdownTimeMap, setimagePieShutdownTimeMap] = useState<Map<number, string>>(new Map());
    const [imagePieOperationTime, setimagePieOperationTime] = useState<undefined | string>(undefined);
    const classes = useStyles();
    const { t } = useTranslation();

    const startOfMonth = moment()
        .utc()
        .month(props.filterData.month)
        .year(props.filterData.year)
        .hour(0)
        .minute(0)
        .startOf("month");

    const endOfMonth = moment()
        .utc()
        .month(props.filterData.month)
        .year(props.filterData.year)
        .hour(23)
        .minute(59)
        .endOf("month");

    const onChangeDataImageChartMtrPerDayWithPerformance = useCallback(
        (newImageData: string): void => {
            setimageChartMtrPerDayWithPerformance(newImageData);
        },
        [setimageChartMtrPerDayWithPerformance]
    );

    const onChangeDataImagePieShutdownTime = useCallback(
        (newImageData: string, id: number): void => {
            const newImagePieShutdownTime = new Map(imagePieShutdownTimeMap);

            newImagePieShutdownTime.set(id, newImageData);

            setimagePieShutdownTimeMap(newImagePieShutdownTime);
        },
        [setimagePieShutdownTimeMap, imagePieShutdownTimeMap]
    );

    const onChangeDataImagePieOperationTime = useCallback(
        (newImageData: string): void => {
            setimagePieOperationTime(newImageData);
        },
        [setimagePieOperationTime]
    );

    const onChangeDataOverview = useCallback(
        (newData: OverallRadarData): void => {
            setoverallRadarData(newData);
        },
        [setoverallRadarData]
    );

    const dataDateRange = startOfMonth.format("DD.") + " - " + endOfMonth.format("DD.MMM YYYY");
    const dataDateRangeShort = startOfMonth.format("MMMM YYYY");
    //    const totalDateRangeTime = endOfMonth.diff(startOfMonth, "h");
    let operationTime = 730; // radar was running and measuring -> sum of all time-bin duration (?)
    let blindTime = 134.7; // time radar was not measuring due to visibility (precipitation) or mode-change -> from weather-table: sum of (weather-property 5)*(time_bin duration) of each time_bin
    let observationTime = 648.6; // operationTime - blindTime
    let shutdownTime = 38.1; //  shutdown time
    let avgMtr = 254.7;
    let nBirds = 43512;
    let altitudeRangeMin = 25;
    let altitudeRangeMax = 700;
    let shutdownTimeCausedByMr1 = 0;

    let energyLoss = 0; //102658.25;

    if (overallRadarData) {
        operationTime = parseFloat((overallRadarData.operationTime / 60).toFixed(0));
        blindTime = parseFloat((overallRadarData.blindTime / 60).toFixed(0));
        observationTime = parseFloat((overallRadarData.observationTime / 60).toFixed(0));
        shutdownTime = parseFloat((overallRadarData.shutdownTime / 60).toFixed(1));
        nBirds = parseFloat((overallRadarData.sumMtr / 12).toFixed(0));
        //        avgMtr = parseFloat((overallRadarData.averageMtr * 1).toFixed(1));
        avgMtr = nBirds / observationTime;
        altitudeRangeMin = parseFloat((overallRadarData.altitudeStart * 1).toFixed(0));
        altitudeRangeMax = parseFloat((overallRadarData.altitudeStop * 1).toFixed(0));
        energyLoss = parseFloat((overallRadarData.sumPowerLoss * 1).toFixed(0));

        if (overallRadarData.windmills && 0 < overallRadarData.windmills.length) {
            let averageShutdownTime = 0;
            let averageShutdownTimeCausedByMr1 = 0;

            let minaltitudeRangeMin = 1000000;
            let maxaltitudeRangeMax = 0;

            let energyLossWindmills = 0;
            overallRadarData.windmills.forEach((windmill): void => {
                averageShutdownTime += windmill.shutdownTime;
                if (
                    windmill.windmillShutdownParameter &&
                    windmill.windmillShutdownParameter[0].valueLocal < minaltitudeRangeMin
                ) {
                    minaltitudeRangeMin = windmill.windmillShutdownParameter[0].valueLocal;
                }
                if (
                    windmill.windmillShutdownParameter &&
                    maxaltitudeRangeMax < windmill.windmillShutdownParameter[1].valueLocal
                ) {
                    maxaltitudeRangeMax = windmill.windmillShutdownParameter[1].valueLocal;
                }

                if (windmill.windmillPerformanceValues) {
                    averageShutdownTimeCausedByMr1 += Number(windmill.windmillPerformanceValues.sumRadarCausedShutdown);
                    energyLossWindmills += windmill.windmillPerformanceValues.sumPowerLoss;
                }
            });
            altitudeRangeMin = minaltitudeRangeMin;
            altitudeRangeMax = maxaltitudeRangeMax;
            shutdownTime = averageShutdownTime / overallRadarData.windmills.length / 60;

            shutdownTimeCausedByMr1 = averageShutdownTimeCausedByMr1 / overallRadarData.windmills.length / 60;
            energyLoss = energyLossWindmills;
        }
    }

    const Overview = React.memo(
        (): React.ReactElement => (
            <ReportDataOverview
                radar={props.radar}
                dateFrom={startOfMonth}
                dateTo={endOfMonth}
                onChangeData={onChangeDataOverview}
            />
        )
    );

    const ChartMtrPerDayWithPerformance = React.memo(
        (): React.ReactElement => {
            return (
                <ReportDataChartMtrPerDayWithPerformance
                    radar={props.radar}
                    dateFrom={startOfMonth}
                    dateTo={endOfMonth}
                    onChangeDataImage={onChangeDataImageChartMtrPerDayWithPerformance}
                />
            );
        }
    );

    const PieOperationTime = React.memo(
        (): React.ReactElement => (
            <ReportDataPieOperationTime
                radar={props.radar}
                dateFrom={startOfMonth}
                dateTo={endOfMonth}
                onChangeDataImage={onChangeDataImagePieOperationTime}
            />
        )
    );

    const backgroundColorShutdownPie: string[] = ["red", "green"];
    const labelsShutdownPie: string[] = [t("shutdownTimes.shutdown"), t("shutdownTimes.running")];

    const shutdownTimePercentageAllWindmills = (shutdownTimeCausedByMr1 * 100) / operationTime;
    const dataAllWindmills: number[] = [shutdownTimePercentageAllWindmills, 100 - shutdownTimePercentageAllWindmills];

    interface PieProps {
        data: number[];
        id: number;
    }

    const Pie = React.memo<PieProps>(
        (localProps): React.ReactElement => (
            <ReportDataPie
                values={localProps.data}
                colors={backgroundColorShutdownPie}
                labels={labelsShutdownPie}
                id={localProps.id}
                onChangeDataImage={onChangeDataImagePieShutdownTime}
            />
        )
    );

    let reportDone = false;
    if (overallRadarData && imagePieShutdownTimeMap && imageChartMtrPerDayWithPerformance && imagePieOperationTime) {
        if (
            overallRadarData.windmills.length === 0 ||
            overallRadarData.windmills.length < imagePieShutdownTimeMap.size
        ) {
            reportDone = true;
        }
    }

    const getShutdownParameterReadableName = (name: string): string => {
        return t("shutdownParameters.names." + name.replace("_bad_visibility", ""));
    };

    const getWindmillPie = (windmill: Windmill): React.ReactElement => {
        if (windmill) {
            return (
                <View>
                    <View style={styles.pieChartColWindmill}>
                        <View>
                            {imagePieShutdownTimeMap && (
                                <Image
                                    src={imagePieShutdownTimeMap.get(windmill.number) ?? ""}
                                    style={styles.pieChartWindmill}
                                />
                            )}
                        </View>
                        <Text style={styles.pieChartTitle}>{windmill.name}</Text>
                    </View>
                    <View style={styles.tableColWindmill}>
                        <Text style={styles.tableCellDescription}>MTR Shutdown Parameters</Text>

                        <View style={styles.table}>
                            {windmill.windmillShutdownParameter.map((windmillParameter: WindmillShutdownParameter) => {
                                return (
                                    <>
                                        {windmillParameter.id.toString() === "1" ? (
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableCellParameterTitle}>Altitude Range</Text>
                                            </View>
                                        ) : (
                                            <></>
                                        )}
                                        {windmillParameter.id.toString() === "4" ? (
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableCellParameterTitle}>
                                                    {t("shutdownParameters.goodVisibility")}
                                                </Text>
                                            </View>
                                        ) : (
                                            <></>
                                        )}
                                        {windmillParameter.id.toString() === "20" ? (
                                            <View style={styles.tableRow}>
                                                <Text style={styles.tableCellParameterTitle}>
                                                    {t("shutdownParameters.badVisibility")}
                                                </Text>
                                            </View>
                                        ) : (
                                            <></>
                                        )}
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableColParameter}>
                                                <Text style={styles.tableCellParameter}>
                                                    {getShutdownParameterReadableName(windmillParameter.name) + ": "}
                                                </Text>
                                            </View>
                                            <View style={styles.tableColParameterValue}>
                                                <Text style={styles.tableCellParameterValue}>
                                                    {windmillParameter.valueLocal}
                                                </Text>
                                            </View>
                                        </View>
                                    </>
                                );
                            })}
                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableCellParameterTitle}>{t("shutdownTimes.title")}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColParameter}>
                                        <Text style={styles.tableCellParameter}>
                                            {t("shutdownTimes.shutdown") + ":"}
                                        </Text>
                                    </View>
                                    <View style={styles.tableColParameterValue}>
                                        <Text style={styles.tableCellParameterValue}>
                                            {(
                                                windmill.windmillPerformanceValues ? (
                                                    windmill.windmillPerformanceValues.sumRadarCausedShutdown / 60) : 0
                                            ).toFixed(1) + " h"}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableColParameter}>
                                        <Text style={styles.tableCellParameter}>
                                            {t("shutdownTimes.totalShutdownTime") + ":"}
                                        </Text>
                                    </View>
                                    <View style={styles.tableColParameterValue}>
                                        <Text style={styles.tableCellParameterValue}>
                                            {(windmill.shutdownTime / 60).toFixed(1) + " h"}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            );
        } else {
            return <></>;
        }
    };

    const getWindmillPieContainer = (windmills: Windmill[]): React.ReactElement => {
        const rows = [];
        for (let i = 0; i < windmills.length; i += 4) {
            const row = [];
            row.push(windmills.slice(i, i + 4).map((windmill) => getWindmillPie(windmill)));
            rows.push(
                <View>
                    <View style={styles.pieChartRow}>
                        {row.map((item) => {
                            return item;
                        })}
                    </View>
                </View>
            );
        }
        return <>{rows}</>;
    };

    return (
        <>
            {reportDone && (
                <PDFViewer width="100%" height="100%">
                    <Document>
                        <Page size="A4" orientation="portrait" style={styles.body} wrap>
                            <View style={styles.headerRow} fixed>
                                <View style={styles.headerCol}>
                                    <Text style={styles.headerTextLeft}>{props.radar.customer}</Text>
                                </View>
                                <View style={styles.headerCol}>
                                    <Text style={styles.headerTextCenter}>{props.radar.projectName}</Text>
                                </View>
                                <View style={styles.headerCol}>
                                    <Text style={styles.headerTextRight}>{dataDateRangeShort}</Text>
                                </View>
                            </View>
                            <View style={styles.headerSpacer}></View>

                            <View style={styles.titleContainer}>
                                <View style={styles.titleRow}>
                                    <Text style={styles.titleText}>Collision Risk Management Report</Text>
                                </View>
                                <View style={styles.titleRow}>
                                    <Text style={styles.subTitleText}>{"Monthly Report, " + dataDateRangeShort}</Text>
                                </View>
                            </View>

                            <View style={styles.table}>
                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Site</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>{props.radar.siteName}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Date</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>{dataDateRange}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellDescription}>Sensor</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>{props.radar.type}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellDescription}>S/N</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>{"BS" + props.radar.serialNumber}</Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Operation Time</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {operationTime.toLocaleString("de-CH", { maximumFractionDigits: 1 })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"h"}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Observation Time</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {observationTime.toLocaleString("de-CH", { maximumFractionDigits: 1 })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"h"}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellDescription}>Blind Time</Text>
                                            <Text style={styles.tableFootNote}>1</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {blindTime.toLocaleString("de-CH", { maximumFractionDigits: 1 })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"h"}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellDescription}>Shutdown Time</Text>
                                            <Text style={styles.tableFootNote}>2</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {shutdownTimeCausedByMr1 !== undefined
                                                    ? shutdownTimeCausedByMr1.toLocaleString("de-CH", {
                                                        maximumFractionDigits: 1
                                                    }) +
                                                    "(" +
                                                    shutdownTime.toLocaleString("de-CH", {
                                                        maximumFractionDigits: 1
                                                    }) +
                                                    ")"
                                                    : shutdownTime.toLocaleString("de-CH", {
                                                        maximumFractionDigits: 1
                                                    })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"h"}</Text>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.tableRow}>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Average MTR</Text>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {avgMtr.toLocaleString("de-CH", { maximumFractionDigits: 1 })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"ind./hr/km"}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <Text style={styles.tableCellDescription}>Number of Birds per km</Text>
                                        <Text style={styles.tableCellValue}>
                                            {nBirds.toLocaleString("de-CH", { maximumFractionDigits: 0 })}
                                        </Text>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellDescription}>Altitude Range</Text>
                                            {overallRadarData &&
                                                overallRadarData.windmills &&
                                                0 < overallRadarData.windmills.length ? (
                                                <Text style={styles.tableFootNote}>4</Text>
                                            ) : (
                                                <></>
                                            )}
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {altitudeRangeMin.toLocaleString("de-CH", {
                                                    maximumFractionDigits: 0
                                                })}
                                            </Text>
                                            <Text style={styles.tableCellValue}>
                                                {"-" +
                                                    altitudeRangeMax.toLocaleString("de-CH", {
                                                        maximumFractionDigits: 1
                                                    })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"m"}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableCol}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellDescription}>Estimated Energy Loss</Text>
                                            {<Text style={styles.tableFootNote}>3</Text>}
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableCellValue}>
                                                {energyLoss.toLocaleString("de-CH", { maximumFractionDigits: 0 })}
                                            </Text>
                                            <Text style={styles.tableCellUnit}>{"kWh"}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.dividingLinePortrait}></View>

                            <View style={styles.pieChartContainer}>
                                <View style={styles.pieChartRow}>
                                    <View style={styles.pieChartCol}>
                                        <View>
                                            {imagePieShutdownTimeMap && imagePieShutdownTimeMap.has(-1) && (
                                                <Image
                                                    src={imagePieShutdownTimeMap.get(-1) ?? ""}
                                                    style={styles.pieChart}
                                                />
                                            )}
                                        </View>
                                        <Text style={styles.pieChartTitle}>Windmill</Text>
                                        <Text style={styles.pieLegendText}>BirdScan Shutdown Times</Text>
                                    </View>
                                    <View style={styles.pieChartCol}>
                                        <View>
                                            {imagePieOperationTime && (
                                                <Image src={imagePieOperationTime} style={styles.pieChart} />
                                            )}
                                        </View>
                                        <Text style={styles.pieChartTitle}>Radar</Text>
                                        <Text style={styles.pieLegendText}>Radar Operation Times</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.dividingLinePortrait}></View>

                            <View style={styles.graphTableContainerPortrait}>
                                <View >
                                    {imageChartMtrPerDayWithPerformance && (
                                        <Image src={imageChartMtrPerDayWithPerformance} style={styles.imagePortrait} />
                                    )}
                                    <View >
                                        <Text style={styles.graphLegendText}>
                                            {"Daily and nightly averaged MTR per day and performance per day."}
                                        </Text>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.footNote} fixed>
                                <View style={styles.footNoteLine} fixed></View>
                                <View style={styles.footNoteRow} fixed>
                                    <Text style={styles.footNoteColBold} fixed>
                                        [1]{" "}
                                    </Text>
                                    <Text style={styles.footNoteCol} fixed>
                                        Time the radar was blind due to rain
                                    </Text>
                                </View>
                                <View style={styles.footNoteRow} fixed>
                                    <Text style={styles.footNoteColBold} fixed>
                                        [2]{" "}
                                    </Text>
                                    <Text style={styles.footNoteCol} fixed>
                                        {shutdownTimeCausedByMr1
                                            ? "Time the BirdScan shut the windpark down and BirdScan was the only cause for the " +
                                            "shutdown (No other cause (technical maintenance, low winds, bats, etc.) prevented the " +
                                            "windpark from beeing in operation). Average over all windmills. In parentheses, total shutdown time ignoring other causes. "
                                            : "Time the BirdScan shut the windpark down"}
                                    </Text>
                                </View>
                                <View style={styles.footNoteRow} fixed>
                                    <Text style={styles.footNoteColBold} fixed>
                                        [3]{" "}
                                    </Text>
                                    <Text style={styles.footNoteCol} fixed>
                                        Estimated energy loss due to BirdScan shutdown
                                    </Text>
                                </View>

                                {overallRadarData &&
                                    overallRadarData.windmills &&
                                    0 < overallRadarData.windmills.length ? (
                                    <View style={styles.footNoteRow} fixed>
                                        <Text style={styles.footNoteColBold} fixed>
                                            [4]{" "}
                                        </Text>
                                        <Text style={styles.footNoteCol} fixed>
                                            Minimal Min and maximal Max value over all windmills
                                        </Text>
                                    </View>
                                ) : (
                                    <></>
                                )}
                            </View>

                            <View style={styles.footerRow} fixed>
                                <View style={styles.footerColEdge}>
                                    <Text style={styles.footerDateText}> {moment().format("DD.MM.YYYY")}</Text>
                                </View>
                                <View style={styles.footerColCenter}>
                                    <Text style={styles.footerContactText}>
                                        Swiss Birdradar Solution AG | Technoparkstrasse 2 | 8406 Winterthur
                                    </Text>
                                    <Text style={styles.footerContactText}>
                                        +41 52 511 24 11 | info@swiss-birdradar.com
                                    </Text>
                                </View>
                                <View style={styles.footerColEdge}>
                                    <Text
                                        style={styles.footerPageText}
                                        render={({ pageNumber, totalPages }): string =>
                                            ` Page ${pageNumber} of ${totalPages}`
                                        }
                                        fixed
                                    />
                                </View>
                            </View>
                        </Page>
                        {overallRadarData && 0 < overallRadarData.windmills?.length && (
                            <Page size="A4" orientation="portrait" style={styles.body} wrap>
                                <View style={styles.headerRow} fixed>
                                    <View style={styles.headerCol}>
                                        <Text style={styles.headerTextLeft}>{props.radar.customer}</Text>
                                    </View>
                                    <View style={styles.headerCol}>
                                        <Text style={styles.headerTextCenter}>{props.radar.projectName}</Text>
                                    </View>
                                    <View style={styles.headerCol}>
                                        <Text style={styles.headerTextRight}>{dataDateRangeShort}</Text>
                                    </View>
                                </View>
                                <View style={styles.headerSpacer}></View>

                                <View style={styles.graphTableContainerPortrait}>
                                    <View style={styles.graphTableRow}>
                                        <Text style={styles.graphLegendText}>
                                            {" "}
                                            {"Shutdown times and parameters for each individual windmill."}
                                        </Text>
                                    </View>
                                </View>

                                <View style={styles.pieChartContainerWindmills}>
                                    {getWindmillPieContainer(overallRadarData.windmills)}
                                </View>


                                <View style={styles.footerRow} fixed>
                                    <View style={styles.footerColEdge}>
                                        <Text style={styles.footerDateText}> {moment().format("DD.MM.YYYY")}</Text>
                                    </View>
                                    <View style={styles.footerColCenter}>
                                        <Text style={styles.footerContactText}>
                                            Swiss Birdradar Solution AG | Technoparkstrasse 2 | 8406 Winterthur
                                        </Text>
                                        <Text style={styles.footerContactText}>
                                            +41 52 511 24 11 | info@swiss-birdradar.com
                                        </Text>
                                    </View>
                                    <View style={styles.footerColEdge}>
                                        <Text
                                            style={styles.footerPageText}
                                            render={({ pageNumber, totalPages }): string =>
                                                ` Page ${pageNumber} of ${totalPages}`
                                            }
                                            fixed
                                        />
                                    </View>
                                </View>
                            </Page>
                        )}
                    </Document>
                </PDFViewer>
            )}
            {!reportDone && (
                <Card className={classes.circularProgressCard}>
                    <CardContent className={classes.circularProgress}>
                        <CircularProgress></CircularProgress>
                    </CardContent>
                </Card>
            )}
            {!overallRadarData && <Overview />}
            {overallRadarData && !imagePieShutdownTimeMap.has(-1) && <Pie data={dataAllWindmills} id={-1} />}

            {overallRadarData &&
                overallRadarData.windmills &&
                0 < overallRadarData.windmills.length &&
                overallRadarData.windmills.map((windmill) => {
                    if (!imagePieShutdownTimeMap.has(windmill.number)) {
                        let shutdownTimePercentage = 0.0;
                        if (windmill.windmillPerformanceValues) {
                            shutdownTimePercentage =
                                ((windmill.windmillPerformanceValues.sumRadarCausedShutdown / 60) * 100) /
                                operationTime;
                        }
                        
                        const data: number[] = [shutdownTimePercentage, 100 - shutdownTimePercentage];

                        return <Pie data={data} id={windmill.number} />;
                    }
                    return <></>;
                })}

            {!imagePieOperationTime && <PieOperationTime />}
            {!imageChartMtrPerDayWithPerformance && <ChartMtrPerDayWithPerformance />}
        </>
    );
};
